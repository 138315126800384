import React, { useEffect, useState } from 'react'

import heroThumb from '../../assets/images/hero-phone.png'

function Hero({ subHeading }) {
  
  return (
    <>
      <section className="appie-hero-area-2">
        <div className="container mb-90">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="appie-hero-content-2">
                <h1 className="appie-title">
                   med{' '}
                  <span style={{ color: '#a37dff' }}>Zimply</span>
                </h1>
                <p>
                  {subHeading}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="appie-hero-thumb-3 wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="400ms"
        >
          <img src={heroThumb} alt="" />
        </div>
      </section>
    </>
  )
}

export default Hero
